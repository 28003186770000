import React from 'react';
import { Typography as MiuTypography } from "@material-ui/core";
import { makeComponentStyles } from "@components/mixins.js"
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const styles = () => ({
    float_right: {
        marginLeft: '15px',
    },
    float_left: {
        marginRight: '15px',
    }
});

const setimg = (Float, breakpoint) => makeStyles(theme => ({
    root: {
        float: Float,
        [theme.breakpoints.down(breakpoint)]: {
            float: 'none',
            width: '100%',
            margin: 0,
            display: 'null'
        }
    }
}))().root;

const setdisplay = (breakpoint) => makeStyles(theme => ({
    root: {
        [theme.breakpoints.down(breakpoint)]: {
            display: 'none'
        }
    }
}))().root;

function FloatWrap({ children, Img, Float = 'left', breakpoint = 'sm', displayNone = false, className = "", ...rest }) {
    const classes = makeComponentStyles(styles);
    const props = {
        ...rest,
        className: className,
    }

    return (
        <MiuTypography
            {...props}
        >
            <img className={clsx(classes[`float_${Float}`], setimg(Float, breakpoint), { [setdisplay(breakpoint)] : displayNone } ) } alt='photo' src={Img} />
            {children}
        </MiuTypography>
    )
}

export default FloatWrap
