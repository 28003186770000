import React, { useContext } from "react"
import Layout from "@components/Layout/Layout";
import Container from "@components/Container/Container.jsx";
import MainWrap from "@components/Wrap/MainWrap";
import { Typography } from "@material-ui/core";
import { Grid } from "@material-ui/core";
import Chip from "@components/Cards/Chip.jsx"
import Avatar from "@material-ui/core/Avatar";
import { makeComponentStyles } from "@components/mixins.js"
import infoLabel from "@static/images/Info.svg"
import { graphql } from "gatsby";
import Markdown from 'markdown-to-jsx';
import { IntlContextConsumer, useIntl } from "gatsby-plugin-react-intl"
import { getStrapiContentByLang } from "../heplers.js"
import FloatWrap from "@components/Wrap/FloatWrap.jsx";
import Seo from "@components/seo"

const styles = ({ palette, breakpoints }) => ({
  text: {
    fontSize: "18px",
    color: palette.text.primary,
    '& $b': {
      fontWeight: 500
    },
    [breakpoints.down('sm')]: {
      fontSize: "14px",
      color: palette.text.black
    }
  },
  chiptext: {
    fontSize: "18px",
    [breakpoints.down('sm')]: {
      fontSize: "14px"
    }
  }
});


const Finance = ({ data }) => {
  const {
    text,
    chiptext,
  } = makeComponentStyles(styles);

  const { language } = useContext(IntlContextConsumer);
  const intl = useIntl();
  const {
    head,
    headimage,
    importantinfo,
    importantImage,
    placesTitle,
    places,
    appendix,
    FinanceSEO,
  } = getStrapiContentByLang(data.allStrapiFinance.edges, language);

  return (
    <div>
      <Seo title={FinanceSEO?.TitleSEO}
           description={FinanceSEO?.DescriptionSEO}
           keywords={FinanceSEO?.KeywordsSEO.split(',')}
      />

      <>
        <Layout>
          <Container>
            <MainWrap>
              <MainWrap.Header>
                <Typography component={"h1"} variant={"h2"}>
                  {intl.formatMessage({ id: "finance" })}
                </Typography>
              </MainWrap.Header>
              <MainWrap.Body>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FloatWrap className={text} Img={headimage.localFile.publicURL} Float='right'>
                      <Markdown>{head}</Markdown>
                    </FloatWrap>
                  </Grid>
                  <Grid item xs={12}>
                    <Chip avatar={<Avatar src={infoLabel} />} label={intl.formatMessage({ id: "important" })} straight className={chiptext} />
                  </Grid>
                  <Grid item xs={12}>
                    <FloatWrap className={text} Img={importantImage.localFile.publicURL} Float='left' displayNone={true}>
                      <Markdown>{importantinfo}</Markdown>
                    </FloatWrap>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={text} style={{ fontWeight: 500 }}>{placesTitle}</Typography>
                    <Typography className={text}>
                      <ol style={{ listStylePosition: 'inside' }}>
                        {
                          places.map(({ office, atmPlace }) => (
                            <li>{office}
                              <ul>
                                {
                                  atmPlace.map(({ atm }) => (
                                    <li type='a' style={{ marginLeft: '18px' }}>{atm}</li>
                                  ))
                                }
                              </ul>
                            </li>
                          ))
                        }
                      </ol>
                    </Typography>
                    <Typography className={text}>{appendix}</Typography>
                  </Grid>
                </Grid>
              </MainWrap.Body>
            </MainWrap>
          </Container>
        </Layout >
      </>
    </div>
  )
}

export default Finance;
export const pageQuery = graphql`{
  allStrapiFinance {
    edges {
      node {
        locale
        head
        headimage {
          localFile {
            publicURL
          }
        }
        importantinfo
        importantImage {
          localFile {
            publicURL
          }
        }
        placesTitle
        places {
          office
          atmPlace {
            atm
          }
        }
        appendix
        FinanceSEO {
          DescriptionSEO
          KeywordsSEO
          TitleSEO
        }
      }
    }
  }
}`
